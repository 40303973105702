import { getCategorias, postParticipante, postRespuestas, postCV } from './service/index'
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import Header from './components/Header'
import Bloque from './components/Bloque'
import FileUploader from './components/FileUploader'
import testUtils from 'react-dom/test-utils';

function sleep(microseconds) {
  var request = new XMLHttpRequest();
  request.open("GET", "sleep.php?time=" + microseconds, false);
  request.send();
}

function App() {
  const [data, setData] = useState([])
  const [idPuesto, setIdPuesto] = useState(null)

  const [nombres, setNombres] = useState('')
  const [apellidos, setApellidos] = useState('')
  const [dpi, setDPI] = useState('')
  const [correo, setCorreo] = useState('')
  const [telefono, setTelefono] = useState('')

  const [respuestas, setRespuestas] = useState([])
  const [file, setFile] = useState(null);
  //validaciones
  const [fileExist, setFileExist] = useState(true);
  const [resExist, setResExist] = useState(true);
  const [isPhoneOk, setIsPhoneOk] = useState(true);
  const [isMailOk, setIsMailOk] = useState(true);
  const [isDpiOk, setIsDpiOk] = useState(true);
  const [ok, setOk] = useState(true)


  useEffect(async () => {
    const newData = []
    const cat = await getCategorias()

    console.log(cat.Mensaje);
    cat.Mensaje.forEach(e => {
      if (e.Estado === 1) {
        const newItem = { value: e.IDReclutamiento, label: e.Puesto }
        newData.push(newItem)
      }
    });

    setData(newData)
  }, [])

  useEffect(() => {
    _validarForm()
    if (isDpiOk && isMailOk && isPhoneOk && file && nombres.length > 2 && apellidos.length > 2 && respuestas.length > 0) setOk(true)
    else setOk(false)
  }, [dpi, correo, telefono, respuestas, file, nombres, apellidos])

  const _validarForm = async () => {
    const patron_entero = /^([0-9])*$/
    const pateon_mail = /^[^@]+@[^@]+\.[a-zA-Z]{2,}$/

    if (!patron_entero.test(dpi) || dpi.length !== 13) setIsDpiOk(false)
    else { setIsDpiOk(true) }

    if (!patron_entero.test(telefono) || telefono.length !== 8) setIsPhoneOk(false)
    else { setIsPhoneOk(true) }

    if (!pateon_mail.test(correo)) setIsMailOk(false)
    else { setIsMailOk(true) }

    if (respuestas.length < 1) setResExist(false)
    else { setResExist(true) }

    if (!file) setFileExist(false)
    else { setFileExist(true) }
  }

  const _putParticipante = async (e) => {
    e.preventDefault() // no recargar la pagina

    // enviar los datos de la persona
    const data = new FormData()
    data.append("Reclutamiento", idPuesto)
    data.append("Nombre", `${nombres},${apellidos}`)
    data.append("DPI", dpi)
    data.append("Telefono", telefono)
    data.append("Correo", correo)
    const res = await postParticipante(data)

    //enviar los datos del puesto
    if (!res.Error) {
      const respuestas_arr = []
      respuestas.forEach(r => {
        respuestas_arr.push({
          Participacion: res.Mensaje.IDParticipacion,
          Opcion: r.Opcion
        })
      })
      await postRespuestas({ Respuestas: respuestas_arr })
    }

    

    // enviar el archivo pdf
    if (file) {
      const archivo = new FormData()
      archivo.append('pdf', file)
      const a = await postCV(idPuesto, res.Mensaje.DPI, archivo)

      //alerta de exito
      if (!a.Error) {
        alert("Solicitud enviada con éxito")
        window.location.reload()
      }
    }

  }

  return (
    <div className="App">

      <div>

        <Header />

        <div className="flex justify-center mt-8">
          <div className="bg-white shadow overflow-hidden sm:rounded-lg xl:w-2/5 lg:w-3/5 md:w-4/5 w-full mx-4 mb-8">
            <form onSubmit={_putParticipante}>

              <div>
                <div className="bg-gray-50 p-6 border-t border-gray-200">
                  <label className="font-medium" >Datos personales</label>
                </div>

                <div className="bg-white px-4 py-3 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Nombres </dt>
                  <input required type="text" onChange={t => setNombres(t.target.value)} className="px-4 py-2 md:w-3/5 xs:w-full input bg-white" />
                </div>

                <div className="bg-white px-4 py-3 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Apellidos </dt>
                  <input required type="text" onChange={t => setApellidos(t.target.value)} className="px-4 py-2 md:w-3/5 xs:w-full input bg-white" />
                </div>

                <div className="bg-white px-4 py-3 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">DPI </dt>
                  {!isDpiOk &&
                    <label className="text-red-800">Ingresa un numero de DPI valido</label>
                  }
                  <input maxLength={13} required type="text" onChange={t => setDPI(t.target.value)} className="px-4 py-2 md:w-3/5 xs:w-full input bg-white" />
                </div>

                <div className="bg-white px-4 py-3 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Correo electronico</dt>
                  {!isMailOk &&
                    <label className="text-red-800">Ingresa un correo electronico valido</label>
                  }
                  <input required type="email" onChange={t => setCorreo(t.target.value)} className="px-4 py-2 md:w-3/5 xs:w-full input bg-white" />
                </div>

                <div className="bg-white px-4 py-3 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Telefono</dt>
                  {!isPhoneOk &&
                    <label className="text-red-800">Ingresa un numero de telefono valido</label>
                  }
                  <input maxLength={8} required type="text" onChange={t => setTelefono(t.target.value)} className="px-4 py-2 md:w-3/5 xs:w-full input bg-white" />
                </div>

              </div>


              <div>
                <div className="bg-gray-50 p-6 border-t border-gray-200">
                  <label className="font-medium" >Elige el puesto al que quieres aplicar</label>
                </div>


                <div className="bg-white px-4 py-5 sm:px-6">
                  {!resExist &&
                    <label className="text-red-800">Selecciona un puesto y responde las preguntas</label>
                  }
                  <Select
                    className="select"
                    placeholder="puesto de trabajo..."
                    defaultValue={idPuesto}
                    onChange={a => setIdPuesto(a.value)}
                    options={data}
                  />
                </div>

                <Bloque id={idPuesto} _setRespuestas={setRespuestas} />


                <div className="form-group file-area px-6 mt-8">
                  {!fileExist &&
                    <label className="text-red-800">Por favor sube tu curriculum en pdf</label>
                  }
                  <FileUploader setFile={setFile} />
                </div>




              </div>


              <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                {ok &&
                  <button type="submit" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-400 focus:outline-none">
                    Enviar respuestas
                  </button>
                }
              </div>

            </form>


          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
