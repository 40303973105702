import { getPreguntas } from '../service/index'
import { useEffect, useState } from 'react';
import Select from 'react-select';

const Bloque = ({ id, _setRespuestas }) => {
    const [preguntas, setPreguntas] = useState([])
    const [respuestas, setRespuestas] = useState([])
  
    useEffect(async () => {
      const res = await getPreguntas(id)
      setPreguntas(res.Mensaje)
    }, [id])
  
    function _preguntas(index) {
      const newData = []
      const newRes = [...preguntas]
      const itemIndex = newRes.findIndex(i => i.IDPregunta === index);
      newRes[itemIndex].Opciones.forEach(o => {
        const newItem = { value: o.IDOpcion, label: o.Opcion }
        newData.push(newItem)
      });
      return newData
    }
  
    const _onChangeSelect = (e, id) => {
      const newRes = [...respuestas]
      const itemIndex = newRes.findIndex(i => i.IDPregunta === id);
      const data = { IDPregunta: id, Opcion: e }
  
      if (itemIndex > -1) newRes[itemIndex].Opcion = e
      else newRes.push(data)
  
      setRespuestas(newRes)
      _setRespuestas(newRes)
    }
  
    return (
      <div>
        {
          preguntas.map(p =>
            <div key={p.IDPregunta} className="">
              <div className="bg-white px-4 py-5 sm:px-6">
                <dt className="text-sm font-medium text-gray-500"> {p.Pregunta} </dt>
  
                <Select
                  placeholder="seleccionar..."
                  defaultValue={null}
                  onChange={a => _onChangeSelect(a.value, p.IDPregunta)}
                  options={_preguntas(p.IDPregunta)}
                />
  
              </div>
            </div>
          )
        }
  
      </div>
    )
  }

  export default Bloque