// import { useEffect, useState } from 'react';

const Header = () => (
    <div id="header" className="bg-white shadow">
      <div className="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:py-8 lg:px-8 lg:flex lg:items-center lg:justify-between">
        <h2 className="tracking-tight text-gray-100">
          <span className="block text-4xl ">Listo para unirte a nosotros</span>
          <span className="block text-blue-400 font-bold text-xl ">Llena este formulario para aplicar a uno de nuestros puestos</span>
        </h2>
      </div>
    </div>
  )

  export default Header