import React, { useState } from 'react';
import Dropzone from 'react-dropzone'
// import Dropzone from 'react-dropzone';


const FileUploader = (props) => {
    const [file, setFile] = useState(null);

    const onLoadFiles = (e) => {
        console.log(e[0]);
        setFile(e[0])
        props.setFile(e[0])
    }

    const rejected = (e) => {
        console.log(e);
        if (e[0].errors[0].code === 'file-too-large') alert('el archivo es demasiado pesado')
        if (e[0].errors[0].code === 'too-many-files') alert('solo puedes subir un archivo')
        if (e[0].errors[0].code === 'file-invalid-type') alert('solo puedes subir archivos pdf')
    }

    return (

        <Dropzone
            accept={'.pdf'}
            maxFiles={1}
            maxSize={5 * (1024 * 1024)}
            onDropRejected={rejected}
            onDrop={onLoadFiles}
        >
            {({ getRootProps, getInputProps }) => (
                <div id="upload-area" {...getRootProps({ className: 'dropzone' })}>
                    <div className="label-line"  >
                        <input {...getInputProps()} />
                        <div className="flex flex-col items-center">
                            <span>{file ? file.name : "Arrastra tu cv aqui"}</span>
                            <span>{file ? "" : "hasta 5 MB"}</span>
                        </div>
                        {file &&
                            <button
                                className="files-clear"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setFile(null)
                                }}></button>
                        }
                    </div>
                </div>
            )}
        </Dropzone>
    )
}

export default FileUploader;